class ProjectFilters {
    constructor() {
        this.awards = document.querySelector('[name="awards"]');
        this.radios = Array.from(document.querySelectorAll('[name="project-category"]'));
        this.projectCards = Array.from(document.querySelectorAll('[data-nelement="project-card"]'));
        this.selectedCategory = '';
        this.awardsToggle = false;

        if (this.radios && this.projectCards) {
            this.init();
        }
    }

    init() {
        this.radios.forEach((radio) => radio.addEventListener('change', () => this.setSelectedCategory(radio.dataset.nvalue)));

        if (this.awards) {
            this.awards.addEventListener('change', () => this.toggleAwards());
        }
    }

    toggleAwards() {
        this.awardsToggle = !this.awardsToggle;
        this.update();
    }

    setSelectedCategory(category) {
        this.selectedCategory = category;
        this.update();
    }

    update() {
        let matchedProjectCards = this.projectCards.filter((projectCard) => {
            if (!this.selectedCategory) {
                return true;
            }

            return projectCard.dataset.ntags.split(',').includes(this.selectedCategory);
        });

        matchedProjectCards = matchedProjectCards.filter((projectCard) => {
            if (this.awardsToggle === false) {
                return true;
            }

            return projectCard.dataset.nawards == 1;
        });
        
        const unmatchedProjectcards = this.projectCards.filter(projectCard => !matchedProjectCards.includes(projectCard));

        matchedProjectCards.forEach((projectCard) => {
            projectCard.classList.remove('hide', 'hidden');
        });

        unmatchedProjectcards.forEach((projectCard) => {
            projectCard.classList.add('hide');
            setTimeout(() => projectCard.classList.contains('hide') && projectCard.classList.add('hidden'), 300);
        });
    }
}

export default new ProjectFilters();
